import React from 'react';
import { useNavigate } from 'react-router-dom';
import './SessionExpiredPopup.css';

const SessionExpiredPopup = ({ onLogout }) => {
  const navigate = useNavigate();

  const handleLogout = () => {
    onLogout();
    navigate('/login');
  };

  return (
    <div className="session-expired-popup">
      <p>Session expired. Please log in again.</p>
      <button onClick={handleLogout}>Login Again</button>
    </div>
  );
};

export default SessionExpiredPopup;
