import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './AgencyDetail.css'; // Import the CSS file

const AgencyDetail = () => {
    const { id } = useParams(); // Get the agency ID from the URL
    const [agency, setAgency] = useState(null);

    useEffect(() => {
        // Fetch agency details using the ID
        const fetchAgency = async () => {
            try {
                const response = await fetch(`https://apptvendors.in/api/agencies/${id}`);
                const data = await response.json();
                console.log('Agency photoUrl:', data.photoUrl);
                setAgency(data);
            } catch (error) {
                console.error('Failed to fetch agency details:', error);
            }
        };

        fetchAgency();
    }, [id]);

    if (!agency) return <div>Loading...</div>;

    return (
        <div className="agency-detail-container">
            <div className="agency-detail-header">
            <img src={agency.photoUrl.startsWith('/') ? agency.photoUrl : `/${agency.photoUrl}`} alt={agency.name} className="agency-photo" />

                <h2>{agency.name}</h2>
            </div>
            <p className="agency-description">{agency.description}</p>
            <ul className="agency-info">
                <li><span>Rate:</span> {agency.rate_per_hour}</li>
                <li><span>Contact:</span> {agency.contact}</li>
            </ul>
            <Link to="/services" className="back-button">Back to Services</Link>
        </div>
    );
};

export default AgencyDetail;
