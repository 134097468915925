import React, { useState } from 'react';
import './Footer.css';
import DisclaimerPopup from './DisclaimerPopup'; // Import the popup component

const Footer = () => {
  const [isDisclaimerOpen, setIsDisclaimerOpen] = useState(false);

  const toggleDisclaimerPopup = () => {
    setIsDisclaimerOpen(!isDisclaimerOpen);
  };

  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-company-info">
          <h4>Company</h4>
          <ul>
            {/* <li>About Us</li>
            <li>Contact</li>
            <li>Privacy Policy</li> */}
            <li onClick={toggleDisclaimerPopup} className="disclaimer-link">Disclaimer</li> {/* Disclaimer link */}
          </ul>
        </div>
        <p>&copy; 2024 Apartment Services. All rights reserved.</p>
      </div>
      {isDisclaimerOpen && <DisclaimerPopup onClose={toggleDisclaimerPopup} />} {/* Disclaimer popup */}
    </footer>
  );
};

export default Footer;
