import { jwtDecode } from 'jwt-decode';

const isTokenExpired = (token) => {
  if (!token) return true;

  try {
    const decodedToken = jwtDecode(token);
    const currentTime = Date.now() / 1000; // Convert to seconds

    return decodedToken.exp < currentTime; // Return true if the token is expired
  } catch (error) {
    return true; // If there's an error decoding, treat the token as expired
  }
};

export default isTokenExpired;
