import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom'; // Import Link
import Navbar from '../Component/Navbar'; // Import Navbar component
import Footer from '../Component/Footer'; // Import Footer component
import './MembersPage.css';

const MembersPage = () => {
  const [members, setMembers] = useState([]);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user'));

  useEffect(() => {
    if (!user) {
      navigate('/login');
      return;
    }

    const fetchMembers = async () => {
      try {
        const response = await axios.get(`https://apptvendors.in/api/members?apartment=${user.apartment_name}`, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
          },
        });
        setMembers(response.data);
      } catch (error) {
        console.error('Failed to fetch members:', error);
      }
    };

    fetchMembers();
  }, [user, navigate]);

  if (!user) return null; // Prevent rendering if no user is found

  return (
    <div>
      <Navbar /> {/* Add Navbar here */}
      <div className="members-page">
        <h1>Members of {user.apartment_name}</h1>
        <div className="members-list">
          {members.length > 0 ? (
            members.map((member) => (
              <div key={member.id} className="member-item">
                <h3>{member.username}</h3>
                <p>Flat No: {member.flat_number}</p>
                <p>User ID: {member.userId}</p> {/* Display the userId here */}
              </div>
            ))
          ) : (
            <p>No members found</p>
          )}
        </div>
        <Link to="/" className="back-to-home-button">Back to Home</Link> {/* Add this line */}
      </div>
      <Footer /> {/* Add Footer here */}
    </div>
  );
};

export default MembersPage;
