// import React, { useEffect, useState } from 'react';
// import axios from 'axios';
// import ServiceCard from '../Component/ServiceCard';
// import Navbar from '../Component/Navbar';
// import Footer from '../Component/Footer';
// import './ServiceSelection.css';
// import ServiceSelectionPopup from '../Component/ServiceSelectionPopup'; // Import the new popup component

// const ServiceSelection = () => {
//   const [services, setServices] = useState([]);
//   const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility

//   useEffect(() => {
//     const fetchServices = async () => {
//       try {
//         const response = await axios.get('https://apptvendors.in/api/services', {
//           headers: { 'x-auth-token': localStorage.getItem('token') }
//         });
//         setServices(response.data);
//       } catch (err) {
//         console.error(err);
//       }
//     };

//     fetchServices();
//   }, []);

//   const handleShowPopup = () => {
//     setShowPopup(true); // Show the popup
//   };

//   const handleClosePopup = () => {
//     setShowPopup(false); // Close the popup
//   };

//   return (
//     <div>
//       <Navbar />
//       <div className="service-selection">
//         <h1>Available Services</h1>
        
//         {/* Move the "All the services you want to avail" section here */}
//         <div className="services-available">
//           <h2>All the services you want to avail</h2>
//           <button onClick={handleShowPopup}>View & Select Services</button>
//         </div>
        
//         <div className="service-grid">
//           {services.map(service => (
//             <ServiceCard key={service.id} service={service} />
//           ))}
//         </div>
//       </div>

//       {/* Popup Component */}
//       {showPopup && (
//         <ServiceSelectionPopup
//           services={services}
//           onClose={handleClosePopup}
//         />
//       )}

//       <Footer />
//     </div>
//   );
// };

// export default ServiceSelection;


import React, { useEffect, useState } from 'react';
import axios from 'axios';
import ServiceCard from '../Component/ServiceCard';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import './ServiceSelection.css';
import ServiceSelectionPopup from '../Component/ServiceSelectionPopup'; // Import the new popup component
import { useNavigate } from 'react-router-dom'; // Import useNavigate

const ServiceSelection = () => {
  const [services, setServices] = useState([]);
  const [showPopup, setShowPopup] = useState(false); // State to manage popup visibility
  const navigate = useNavigate(); // Initialize navigate

  useEffect(() => {
    const fetchServices = async () => {
      try {
        const response = await axios.get('https://apptvendors.in/api/services', {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setServices(response.data);
      } catch (err) {
        console.error(err);
      }
    };

    fetchServices();
  }, []);

  const handleShowPopup = () => {
    setShowPopup(true); // Show the popup
  };

  const handleClosePopup = () => {
    setShowPopup(false); // Close the popup
  };

  // Function to navigate to the service users table page
  const handleViewServiceUsers = () => {
    navigate('/service-users'); // Navigate to the ServiceUsersTable page
  };

  return (
    <div>
      <Navbar />
      <div className="service-selection">
        {/* Add the button to view Service Users in the top corner */}
        
        <h1>Available Services</h1>
        
        {/* Move the "All the services you want to avail" section here */}
        <div className="services-available">
          <h2>All the services you want to avail</h2>
          <button onClick={handleShowPopup}>View & Select Services</button>
          <button  onClick={handleViewServiceUsers}>
          View Service Users
        </button>

        </div>
        
        <div className="service-grid">
          {services.map(service => (
            <ServiceCard key={service.id} service={service} />
          ))}
        </div>
      </div>

      {/* Popup Component */}
      {showPopup && (
        <ServiceSelectionPopup
          services={services}
          onClose={handleClosePopup}
        />
      )}

      <Footer />
    </div>
  );
};

export default ServiceSelection;
