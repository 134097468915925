// // src/pages/SignUp.js
// import React, { useState } from 'react';
// import axios from 'axios';
// import { useNavigate } from 'react-router-dom';
// import './SignUp.css';

// const SignUp = () => {
//   const [formData, setFormData] = useState({
//     username: '',
//     email: '',
//     password: '',
//     phone_number: '', // Added phone_number field
//     apartment_name: '',
//     flat_number: ''
//   });

//   const navigate = useNavigate();

//   const handleChange = (e) => {
//     setFormData({ ...formData, [e.target.name]: e.target.value });
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     try {
//       const response = await axios.post('https://apptvendors.in/api/auth/register', formData);
//       localStorage.setItem('token', response.data.token);
//       navigate('/');
//     } catch (err) {
//       console.error(err.response.data);
//     }
//   };

//   return (
//     <div className="auth-form">
//       <h1>Sign Up</h1>
//       <form onSubmit={handleSubmit}>
//         <input type="text" name="username" placeholder="Username" onChange={handleChange} required />
//         <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
//         <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
//         <input type="text" name="phone_number" placeholder="Phone Number" onChange={handleChange} required /> {/* New field */}
//         <input type="text" name="apartment_name" placeholder="Apartment Name" onChange={handleChange} required />
//         <input type="text" name="flat_number" placeholder="Flat Number" onChange={handleChange} required />
//         <button type="submit">Sign Up</button>
//       </form>
//       <p>Already have an account? <a href="/login">Login here</a></p> {/* Added link to login page */}
//     </div>
//   );
// };

// export default SignUp;


import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import './SignUp.css';

const SignUp = () => {
  const [formData, setFormData] = useState({
    username: '',
    email: '',
    password: '',
    phone_number: '',
    apartment_name: '',
    flat_number: ''
  });

  const [apartments, setApartments] = useState([]); // State to hold apartments list
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch the list of apartments when the component mounts
    const fetchApartments = async () => {
      try {
        const response = await axios.get('https://apptvendors.in/api/apartments');
        setApartments(response.data);
      } catch (error) {
        console.error('Failed to fetch apartments:', error);
      }
    };

    fetchApartments();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('https://apptvendors.in/api/auth/register', formData);
      localStorage.setItem('token', response.data.token);
      navigate('/');
    } catch (err) {
      console.error(err.response.data);
    }
  };

  return (
    <div className="auth-form">
      <h1>Sign Up</h1>
      <form onSubmit={handleSubmit}>
        <input type="text" name="username" placeholder="Username" onChange={handleChange} required />
        <input type="email" name="email" placeholder="Email" onChange={handleChange} required />
        <input type="password" name="password" placeholder="Password" onChange={handleChange} required />
        <input type="text" name="phone_number" placeholder="Phone Number" onChange={handleChange} required />
        
        {/* Dropdown for selecting apartment */}
        <select name="apartment_name" onChange={handleChange} required>
          <option value="">Select Apartment</option>
          {apartments.map(apartment => (
            <option key={apartment.id} value={apartment.name}>
              {apartment.name} - {apartment.location}
            </option>
          ))}
        </select>

        <input type="text" name="flat_number" placeholder="Flat Number" onChange={handleChange} required />
        <button type="submit">Sign Up</button>
      </form>
      <p>Already have an account? <a href="/login">Login here</a></p>
    </div>
  );
};

export default SignUp;

