import React, { useState } from 'react';
import './ServiceCard.css';
import ServicePopup from './ServicePopup'; // Assuming you have created this component as per the previous instruction

const ServiceCard = ({ service }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [serviceDetails, setServiceDetails] = useState(null);
  const [agencies, setAgencies] = useState([]);

  const handleCardClick = async () => {
    try {
      // Fetch service details, including the associated agencies
      const response = await fetch(`https://apptvendors.in/api/services/${service.id}  `);
      const data = await response.json();
      
      setServiceDetails(service); // Keep the service details as they are
      setAgencies(data); // Set the agencies associated with the service
      
      setShowPopup(true);
    } catch (error) {
      console.error('Failed to fetch service details:', error);
    }
  };

  return (
    <div className="service-card">
      <img src={service.image_url} alt={service.name} />
      <h2>{service.name}</h2>
      <button onClick={handleCardClick}>View Agencies</button>

      {showPopup && (
        <ServicePopup
          service={serviceDetails} // Pass the service details
          agencies={agencies} // Pass the agencies list
          onClose={() => setShowPopup(false)}
        />
      )}
    </div>
  );
};

export default ServiceCard;
