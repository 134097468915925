import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ServiceUsersTable.css';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';
import { useNavigate } from 'react-router-dom'; // Import useNavigate to redirect unauthenticated users

const ServiceUsersTable = () => {
  const [data, setData] = useState([]);
  const [groupedData, setGroupedData] = useState({});
  const [user, setUser] = useState(null);
  const navigate = useNavigate(); // For redirection

  useEffect(() => {
    const token = localStorage.getItem('token'); // Check if token is available

    // If no token, redirect to login page
    if (!token) {
      navigate('/login');
      return;
    }

    // Get the user info from localStorage
    const storedUser = localStorage.getItem('user');
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }

    // Fetch data directly from the backend
    const fetchData = async () => {
      try {
        // Make the API call with the token in headers
        const response = await axios.get('https://apptvendors.in/api/service-users', {
          headers: {
            'x-auth-token': token, // Include the token in the request headers
          },
        });

        const fetchedData = response.data;
        console.log('Fetched Data:', fetchedData); 
        
        // Group data by service and set it to state
        groupDataByService(fetchedData);
      } catch (error) {
        console.error('Failed to fetch data:', error);
        // If token is invalid, clear token and redirect to login
        if (error.response && error.response.status === 401) {
          localStorage.removeItem('token');
          localStorage.removeItem('user');
          navigate('/login');
        }
      }
    };

    fetchData();
  }, [navigate]); // Added `navigate` as a dependency

  // Function to group data by service
  const groupDataByService = (fetchedData) => {
    const grouped = fetchedData.reduce((acc, entry) => {
      const serviceName = entry.Service.name;
      if (!acc[serviceName]) {
        acc[serviceName] = [];
      }
      acc[serviceName].push(entry);
      return acc;
    }, {});

    setGroupedData(grouped);
  };

  return (
    <>
      <Navbar />
      <div className="service-users-table">
        {user && (
          <h2>Service Users for <strong>{user.apartment_name}</strong></h2>
        )}
        <table>
          <thead>
            <tr>
              <th>Service</th>
              <th>Vendor</th>
              <th>Interested User</th>
              <th>Flat No</th>
              <th>User ID</th>
            </tr>
          </thead>
          <tbody>
            {Object.keys(groupedData).length > 0 ? (
              Object.keys(groupedData).map((serviceName, index) => (
                <React.Fragment key={index}>
                  <tr className="service-row">
                    <td colSpan="5" className="service-name"><strong>{serviceName}</strong></td>
                  </tr>
                  {groupedData[serviceName].map((entry, idx) => (
                    <tr key={idx}>
                      <td></td> {/* Empty cell for service name, since it's displayed above */}
                      <td>{entry.Agency?.name || 'NA' }</td>
                      <td>{entry.User?.username || 'NA'}</td>
                      <td>{entry.User?.flat_number || 'NA'}</td>
                      <td>{entry.User?.userId || 'NA'}</td>
                    </tr>
                  ))}
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan="5">No data available</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Footer />
    </>
  );
};

export default ServiceUsersTable;
