
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Navbar.css';

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [members, setMembers] = useState([]);
  const isAuthenticated = !!localStorage.getItem('token');
  const user = JSON.parse(localStorage.getItem('user'));
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.setItem('manualLogout', 'true'); // Set the manual logout flag
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    navigate('/'); // Redirect to Home after logout
    window.location.reload();
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (isAuthenticated && user?.apartment_name) {
      // Temporarily comment out the API call
      // const fetchMembers = async () => {
      //   try {
      //     const response = await fetch(`https://apptvendors.in/api/members?apartment=${user.apartment_name}`, {
      //       headers: {
      //         'x-auth-token': localStorage.getItem('token'),
      //       },
      //     });
      //     const data = await response.json();
      //     setMembers(data);
      //   } catch (error) {
      //     console.error('Failed to fetch members:', error);
      //   }
      // };
  
      // fetchMembers();
      console.log("API call skipped to check for repeated query issue");
    }
  }, [isAuthenticated, user?.apartment_name]);
  
  return (
    <nav className="navbar">
      <h1>Appartment Services</h1>
      <div className="navbar-menu-icon" onClick={toggleMenu}>
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
      <div className={`navbar-links ${isOpen ? 'open' : ''}`}>
        <Link to="/">Home</Link>
        {isAuthenticated ? (
          <>
            <Link to="/services">Services</Link>
            <Link to="/members">Members</Link> {/* Link to the Members page */}
            <button onClick={handleLogout}>Logout</button>
          </>
        ) : (
          <>
            <Link to="/login">Login</Link>
            <Link to="/signup">Sign Up</Link>
          </>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
