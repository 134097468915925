import React, { useEffect, useState } from 'react';
import isTokenExpired from '../pages/isTokenExpired';
import SessionExpiredPopup from './SessionExpiredPopup';

const SessionMonitor = () => {
  const [sessionExpired, setSessionExpired] = useState(false);

  useEffect(() => {
    const checkSession = () => {
      const token = localStorage.getItem('token');
      const manualLogout = localStorage.getItem('manualLogout');

      // If there's no token and no manual logout, do nothing
      if (!token) {
        setSessionExpired(false); // Ensure no popup is shown for new users
        return;
      }

      // If the manual logout flag is set, don't show the popup and remove the flag
      if (manualLogout) {
        localStorage.removeItem('manualLogout');
        setSessionExpired(false); // Ensure no popup is shown after manual logout
        return;
      }

      // If the token exists but is expired, show the session expired popup
      if (isTokenExpired(token)) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        setSessionExpired(true);
      }
    };

    // Run the check on component mount
    checkSession();

    // Optionally, check the session at regular intervals
    const intervalId = setInterval(checkSession, 5 * 60 * 1000); // Check every 5 minutes

    // Clean up interval on unmount
    return () => clearInterval(intervalId);
  }, []);

  return sessionExpired ? <SessionExpiredPopup onLogout={() => setSessionExpired(false)} /> : null;
};

export default SessionMonitor;
