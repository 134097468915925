import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './ServiceSelectionPopup.css';
import 'boxicons'; // Import Boxicons

const ServiceSelectionPopup = ({ services, onClose }) => {
  const [selectedServices, setSelectedServices] = useState({});
  const [preferredVendors, setPreferredVendors] = useState({});
  const [vendorDetails, setVendorDetails] = useState({});

  useEffect(() => {
    const fetchUserServices = async () => {
      try {
        const response = await axios.get('https://apptvendors.in/api/user-services', {
          headers: { 'x-auth-token': localStorage.getItem('token') },
        });

        const savedServices = response.data.map(userService => ({
          serviceId: userService.serviceId,
          preferredVendor: userService.agencyId,
        }));

        const initialSelectedServices = {};
        const initialPreferredVendors = {};
        const initialVendorDetails = {};

        await Promise.all(
          savedServices.map(async ({ serviceId, preferredVendor }) => {
            if (preferredVendor) {
              const vendorResponse = await axios.get(`https://apptvendors.in/api/agencies/${preferredVendor}`);
              initialVendorDetails[serviceId] = vendorResponse.data;
            }
            initialSelectedServices[serviceId] = true;
            initialPreferredVendors[serviceId] = preferredVendor;
          })
        );

        setSelectedServices(initialSelectedServices);
        setPreferredVendors(initialPreferredVendors);
        setVendorDetails(initialVendorDetails);
      } catch (err) {
        console.error('Failed to fetch user services:', err);
      }
    };

    fetchUserServices();
  }, []);

  const handleCheckboxChange = (serviceId) => {
    setSelectedServices((prevSelectedServices) => ({
      ...prevSelectedServices,
      [serviceId]: !prevSelectedServices[serviceId],
    }));
  };

  const handlePreferredVendorChange = async (serviceId, agencyId) => {
    setPreferredVendors((prevPreferredVendors) => ({
      ...prevPreferredVendors,
      [serviceId]: agencyId,
    }));

    if (agencyId) {
      try {
        const response = await axios.get(`https://apptvendors.in/api/agencies/${agencyId}`);
        setVendorDetails((prevDetails) => ({
          ...prevDetails,
          [serviceId]: response.data,
        }));
      } catch (err) {
        console.error('Failed to fetch vendor details:', err);
      }
    } else {
      setVendorDetails((prevDetails) => ({
        ...prevDetails,
        [serviceId]: null,
      }));
    }
  };

  // const handleSave = async () => {
  //   try {
  //     const selectedServiceIds = Object.keys(selectedServices).filter(serviceId => selectedServices[serviceId]);

  //     const servicesToSave = selectedServiceIds.map(serviceId => ({
  //       serviceId,
  //       preferredVendor: preferredVendors[serviceId] || null,
  //     }));

  //     await axios.post('https://apptvendors.in/api/user-services', {
  //       services: servicesToSave,
  //     }, {
  //       headers: { 'x-auth-token': localStorage.getItem('token') },
  //     });

  //     alert('Services and preferred vendors saved successfully!');
  //     onClose();
  //   } catch (err) {
  //     console.error('Failed to save services:', err);
  //     alert('Failed to save services');
  //   }
  // };

  const handleSave = async () => {
    try {
      const selectedServiceIds = Object.keys(selectedServices).map(serviceId => ({
        serviceId,
        preferredVendor: selectedServices[serviceId] ? preferredVendors[serviceId] : null, // Unchecked services should send `null`
      }));
  
      await axios.post('https://apptvendors.in/api/user-services', {
        services: selectedServiceIds,
      }, {
        headers: { 'x-auth-token': localStorage.getItem('token') },
      });
  
      alert('Services and preferred vendors saved successfully!');
      onClose();
    } catch (err) {
      console.error('Failed to save services:', err);
      alert('Failed to save services.');
    }
  };
  

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        {/* Close icon directly */}
        <div className='boss'>
        <box-icon name='x-circle' className="close-icon" size="lg" onClick={onClose}></box-icon>
        </div>
        <h2>Select Services and Preferred Vendors</h2>
        <div className="service-selection-grid">
          {services.map(service => (
            <div key={service.id} className="service-selection-row">
              <span>{service.name}</span>
              {selectedServices[service.id] && (
                <>
                  <select
                    value={preferredVendors[service.id] || ''}
                    onChange={(e) => handlePreferredVendorChange(service.id, e.target.value)}
                  >
                    <option value="">Select Preferred Vendor</option>
                    {service.agencies.map(agency => (
                      <option key={agency.id} value={agency.id}>
                        {agency.name}
                      </option>
                    ))}
                  </select>
                  {vendorDetails[service.id] && (
                    <div className="vendor-details">
                      <p>Rate: {vendorDetails[service.id].rate_per_hour}</p>
                      <p>Phone: {vendorDetails[service.id].contact}</p>
                    </div>
                  )}
                </>
              )}
              <input
                type="checkbox"
                checked={!!selectedServices[service.id]}
                onChange={() => handleCheckboxChange(service.id)}
              />
            </div>
          ))}
        </div>
        <button onClick={handleSave}>Save</button>
        <button onClick={onClose}>Close</button>
      </div>
    </div>
  );
};

export default ServiceSelectionPopup;
