import React from 'react';
import 'boxicons'; // Import Boxicons for close icon
import './DisclaimerPopup.css'; // Import the popup styles

const DisclaimerPopup = ({ onClose }) => {
  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className='box'>

        {/* Close icon without button */}
        <box-icon 
          name='x-circle' 
          color="#333" 
          size="lg" 
          className="close-icon" 
          onClick={onClose}>
        </box-icon>
        </div>
        <h2>Disclaimer</h2>
        <p>
          The information provided on this website, including contact details and addresses of vendors such as electricians, plumbers, tile installers, window fixture providers, etc., is for informational purposes only. The website merely facilitates the sharing of vendor information and does not endorse, recommend, or guarantee the services provided by any vendor listed.
        </p>
        <p>
          By using this website, you acknowledge and agree that:
          <ul>
            <li>The website holds no responsibility for the quality, reliability, or outcomes of services provided by any vendor.</li>
            <li>All interactions, including payments, agreements, or service arrangements, are strictly between the user and the vendor.</li>
            <li>The website is not liable for any disputes, losses, or damages arising from conflicts between the user and the vendor.</li>
            <li>It is the responsibility of the user to independently verify the qualifications and credibility of the vendor before engaging their services.</li>
            <li>The website disclaims any and all responsibility for any issues that may arise from the use of vendor services.</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default DisclaimerPopup;
