// src/pages/ServiceDetail.js
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useParams, Navigate } from 'react-router-dom';
import Navbar from '../Component/Navbar';
import Footer from '../Component/Footer';

const ServiceDetail = () => {
  const { id } = useParams();
  const [agencies, setAgencies] = useState([]);
  const [isAuthenticated, setIsAuthenticated] = useState(!!localStorage.getItem('token'));

  useEffect(() => {
    const fetchAgencies = async () => {
      try {
        const response = await axios.get(`https://apptvendors.in/api/services/${id}/agencies`, {
          headers: { 'x-auth-token': localStorage.getItem('token') }
        });
        setAgencies(response.data);
      } catch (err) {
        console.error(err);
        setIsAuthenticated(false);
      }
    };

    if (isAuthenticated) {
      fetchAgencies();
    }
  }, [id, isAuthenticated]);

  if (!isAuthenticated) {
    return <Navigate to="/login" />;
  }

  return (
    <div>
      <Navbar />
      <div className="service-detail">
        <h1>Agencies for Service</h1>
        <div className="agency-list">
          {agencies.map(agency => (
            <div key={agency.id} className="agency-card">
              <h2>{agency.name}</h2>
              <p>Rate: {agency.rate_per_hour} per hour</p>
              <p>Contact: {agency.contact}</p>
              <p>{agency.description}</p>
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default ServiceDetail;
